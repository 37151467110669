import EnrolledDiscipline from "./EnrolledDiscipline";
import EnrolledCourse from "./EnrolledCourse";
import { type, validate } from "../helpers/InterfaceValidators";

class User {
  constructor(properties = {}) {
    this.id = validate(type("number"), parseInt(properties.id));
    this.ra = validate(type("string"), properties.ra);
    this.semester = validate(type("string"), properties.semester);
    this.email = validate(type("string"), properties.email);
    this.firstName = validate(type("string"), properties.firstName);
    this.lastName = validate(type("string"), properties.lastName);
    this.role = validate(type("string"), properties.role);
    this.viewAs = validate(type("boolean", false), properties.viewAs);
    this.token = validate(type("string"), properties.token);
    this.fullName = validate(type("string"), properties.fullName);
    this.profileImage = validate(type("string"), properties.profileImage);
    this.profileImageSmall = validate(type("string"), properties.profileImageSmall);
    this.brand = validate(type("string", "default"), properties.brand ? properties.brand.toLowerCase().normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .replace(/\s/g, "") : undefined);
    this.theme = validate(type("string", "default"), properties.theme ? properties.theme.toLowerCase().normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .replace(/\s/g, "") : undefined);
    this.enrolledDisciplines = validate(type("array", [], ["interface", EnrolledDiscipline]), properties.enrolledDisciplines);
    this.enrolledCourses = validate(type("array", [], ["interface", EnrolledCourse]), properties.enrolledCourses);
    this.tour = validate(type("boolean", false), properties.tour);
  }
}
export default User;
