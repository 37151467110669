import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import propertiesReducer from "./properties/PropertiesReducer";
import propertiesAction from "./properties/PropertiesAction";
import settingsReducer from "./settings/SettingsReducer";
import settingsAction from "./settings/SettingsAction";
import tourReducer from "./tour/TourReducer";
import tourAction from "./tour/TourAction";

let globalReducer = combineReducers({
  properties: propertiesReducer,
  settings: settingsReducer,
  tour: tourReducer
});
const globalAction = {
  properties: propertiesAction,
  settings: settingsAction,
  tour: tourAction
};
globalReducer = persistReducer({
  key: "global",
  storage,
  whitelist: ["tour"],
  blacklist: []
}, globalReducer);
export { globalReducer, globalAction };
