import * as queryString from "query-string";
import store2 from "store2";
import registerApplications from "./services/RegisterApplications";
import authenticate from "./services/Authentication";
import "./App.scss";

const loginRedirect = "https://login.kroton.com.br";
const queryValues = queryString.parse(window.location.search);
const userData = store2.namespace("userData");
const tokenQueryString = queryValues.token;
const tokenLocalStorage = userData("token");

const getUserToken = () => {
  if (tokenQueryString) userData("token", tokenQueryString);

  return userData("token");
};

const initialChangeDOM = () => {
  document.getElementById("loader").remove();
  document.getElementById("layout-base").style.display = "flex";
};

const initialize = async () => {
  try {
    await authenticate(getUserToken());
    registerApplications();
    initialChangeDOM();
  } catch (err) {
    window.location = loginRedirect;
  }
};

if (tokenQueryString === undefined && tokenLocalStorage === null) window.location = loginRedirect;
window.addEventListener("load", initialize);
