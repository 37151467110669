import Interceptor from "../../../helpers/Interceptor";
import { actions } from "../../index";

export const _TYPES = {
  _setToken: "AUTHENTICATION::SET_TOKEN",
  _getUserInfo: "AUTHENTICATION::GET_USER_INFO",
  _changeAva: "AUTHENTICATION::CHANGE_AVA"
};
export const _STATUS = {
  _fetching: "FETCHING",
  _done: "DONE",
  _failed: "FAILED"
};
const userInfo = {};
userInfo.logout = (forceKeepToken = false) => (dispatch, getState) => {
  const role = getState().auth.user.role;
  dispatch({ type: "LOGOUT", status: _STATUS._fetching });
  if (forceKeepToken) {
    dispatch({
      type: "LOGOUT", status: _STATUS._done, role, redirect: undefined
    });
  } else {
    new Interceptor(undefined, dispatch, getState()).getInstance()
      .then(client => {
        client
          .get("/logout")
          .then(response => {
            dispatch({
              type: "LOGOUT",
              status: _STATUS._done,
              role,
              redirect: response.data.success
            });

            return response;
          })
          .catch(error => {
            dispatch({ type: "LOGOUT", status: _STATUS._failed, error });
            throw error;
          });
      })
      .catch(error => {
        dispatch({ type: "LOGOUT", status: _STATUS._failed, error });
        throw error;
      });
  }
};
userInfo.changeAva = (shortName = "kls") => (dispatch, getState) => {
  dispatch({ type: _TYPES._changeAva, status: _STATUS._fetching });
  new Interceptor(undefined, dispatch, getState()).getInstance()
    .then(client => {
      client({
        baseURL: process.env.REACT_APP_API_TROCA_ENTRE_AVAS,
        method: "get",
        url: `/shortname/${shortName}/ava/moodle`
      })
        .then(response => {
          dispatch({
            type: _TYPES._changeAva,
            status: _STATUS._done,
            redirect: response.data.success
          });

          return response;
        })
        .catch(error => {
          dispatch({
            type: _TYPES._changeAva,
            status: _STATUS._failed,
            error
          });
          dispatch(actions.global.properties.setShowToast(true, error.message, "danger", "exclamation-triangle", error.command, true));
          throw error;
        });
    })
    .catch(error => {
      dispatch({ type: _TYPES._changeAva, status: _STATUS._failed, error });
      throw error;
    });
};
// userInfo.setUserToken = (token, refreshtoken) => dispatch => {
//   if (token !== null) {
//     dispatch({
//       type: _TYPES._setToken,
//       token,
//       refreshtoken,
//       status: _STATUS._fetching
//     });
//   }
// };
// userInfo.getUserInfo = () => (dispatch, getState) => {
//   dispatch({
//     type: _TYPES._getUserInfo,
//     status: _STATUS._fetching
//   });
//   new Interceptor(undefined, dispatch, getState()).getInstance()
//     .then(client => {
//       client
//         .get(`/login?t=${new Date().getTime()}`)
//         .then(response => {
//           if (!response.data || !response.data.data) {
//             dispatch({
//               type: _TYPES._getUserInfo,
//               status: _STATUS._failed,
//               error: new Error("Não foi possível obter as informações deste usuário!")
//             });
//           } else {
//             if (
//               !response.data.customdata
//                 || response.data.customdata.shortnames === undefined
//             ) {
//               dispatch({
//                 type: _TYPES._getUserInfo,
//                 status: _STATUS._failed,
//                 error: new Error("Nenhuma matrícula encontrada!")
//               });

//               return;
//             }
//             const user = Object.values(response.data.customdata.shortnames);
//             if (user.length === 0) {
//               dispatch({
//                 type: _TYPES._getUserInfo,
//                 status: _STATUS._failed,
//                 error: new Error("Nenhuma matrícula encontrada!")
//               });

//               return;
//             }
//             if (response.data.customdata.role && response.data.customdata.role !== "tutor") {
//               window.location = "https://login.kroton.com.br";
//             } else {
//               const data = {
//                 ...response.data.data[0],
//                 role: response.data.customdata.role
//               };
//               dispatch({
//                 type: _TYPES._getUserInfo,
//                 status: _STATUS._done,
//                 brand: response.data.customdata.brand,
//                 enrolledDisciplines: response.data.customdata.shortnames
//                   ? Object.values(response.data.customdata.shortnames)
//                   : [],
//                 payload: data
//               });
//             }
//           }

//           return response;
//         })
//         .catch(error => {
//           if (error.code === 404) {
//             // TODO: Melhorar redirecionamento do usuário
//             window.location = "https://login.kroton.com.br";
//           } else {
//             dispatch({
//               type: _TYPES._getUserInfo,
//               status: _STATUS._failed,
//               error
//             });
//             throw error;
//           }
//         });
//     })
//     .catch(error => {
//       dispatch({
//         type: _TYPES._getUserInfo,
//         status: _STATUS._failed,
//         token: null,
//         refreshToken: null,
//         error
//       });
//       throw error;
//     });
// };
export default userInfo;
