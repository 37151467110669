import store2 from "store2";
import axios from "axios";
import { reducers } from "../redux";

const listenRedux = () => {
  reducers.store.subscribe(() => {
    const loginRedirect = "https://login.kroton.com.br";
    const user = reducers.store.getState().auth.user;

    if (user.type === "LOGOUT" && user.status === "DONE") {
      store2.clearAll();
      window.location = loginRedirect;
    }
    if (user.type === "AUTHENTICATION::CHANGE_AVA" && user.status === "DONE") {
      window.location = user.redirect;
    }
    if (user.type === "AUTHENTICATION::GET_USER_INFO" && user.status === "FAILED") {
      window.location = loginRedirect;
    }
  });
};

const saveUserData = (token, response) => {
  const data = {
    ...response.data.data[0],
    role: response.data.customdata.role,
    token
  };
  if (data.role !== "editingteacher") {
    reducers.store.dispatch({
      type: "AUTHENTICATION::GET_USER_INFO",
      status: "FAILED",
      error: "Você não tem acesso à essa página"
    });
  } else {
    reducers.store.dispatch({
      type: "AUTHENTICATION::GET_USER_INFO",
      status: "DONE",
      brand: response.data.customdata.brand,
      enrolledDisciplines: response.data.customdata.shortnames
        ? Object.values(response.data.customdata.shortnames)
        : [],
      payload: data
    });
  }
};

const authenticate = async token => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_LOGIN,
    timeout: 9000,
    headers: { Authorization: `Bearer ${token}` }
  });
  try {
    listenRedux();
    const response = await instance.get(`/login?t=${new Date().getTime()}`);
    saveUserData(token, response);
  } catch (err) {
    throw new Error("Não foi possivel autenticar o usuário");
  }
};

export default authenticate;
