export const _TYPES = {
  _setShowDrawerTree: "PROPERTIES::SET_SHOW_DRAWER_TREE",
  _setShowDrawerProfile: "PROPERTIES::SET_SHOW_DRAWER_PROFILE",
  _setShowDropdownMenu: "PROPERTIES::SET_SHOW_DROPDOWN_MENU",
  _setShowPointsDetails: "PROPERTIES::SET_SHOW_POINTS_DETAILS",
  _forceShowTree: "PROPERTIES::FORCE_SHOW_TREE",
  _setShowToast: "PROPERTIES::SET_SHOW_TOAST",
  _setShowFloatButton: "PROPERTIES::SET_SHOW_FLOAT_BUTTON",
  _setViewAsClosed: "PROPERTIES::SET_VIEW_AS_CLOSED"
};
export const _STATUS = {
  _fetching: "FETCHING",
  _done: "DONE",
  _failed: "FAILED"
};
const propertiesAction = {};
propertiesAction.setShowDrawerTree = show => dispatch => {
  dispatch({ type: _TYPES._setShowDrawerTree, show });
};
propertiesAction.setShowDrawerProfile = show => dispatch => {
  dispatch({ type: _TYPES._setShowDrawerProfile, show });
};
propertiesAction.setShowPointsDetails = show => dispatch => {
  dispatch({ type: _TYPES._setShowPointsDetails, show });
};
propertiesAction.setShowDropdownMenu = show => dispatch => {
  dispatch({ type: _TYPES._setShowDropdownMenu, show });
};
propertiesAction.forceShowTree = force => dispatch => {
  dispatch({ type: _TYPES._forceShowTree, force });
};
propertiesAction.setShowToast = (show, msg = "", state, icon, onCopyRequest, onPrintScreen) => (dispatch, getState) => {
  dispatch({
    type: _TYPES._setShowToast,
    details: {
      show, msg, state, icon, onCopyRequest: getState().global.settings.debugMode ? onCopyRequest : undefined, onPrintScreen: getState().global.settings.debugMode ? onPrintScreen : undefined
    }
  });
};
propertiesAction.setShowFloatButton = (visible, leftIcon, rightIcon, title, onClick) => dispatch => {
  dispatch({
    type: _TYPES._setShowFloatButton, visible, leftIcon, rightIcon, title, onClick
  });
};
propertiesAction.setViewAsClosed = closed => dispatch => {
  dispatch({ type: _TYPES._setViewAsClosed, closed });
};

export default propertiesAction;
