import { type, validate } from "../helpers/InterfaceValidators";

class EnrolledDiscipline {
  constructor(properties = {}) {
    this.shortname = validate(type("string", "-"), properties.shortname);
    this.shortnameGroup = validate(type("string"), properties.shortnameGroup);
    this.cdCurso = validate(type("string"), properties.cdCurso);
    this.cdDisciplina = validate(type("string"), properties.cdDisciplina);
    this.cdInstituicao = validate(type("string"), properties.cdInstituicao);
    this.cdPessoa = validate(type("string"), properties.cdPessoa);
    this.cdTurma = validate(type("string"), properties.cdTurma);
    this.fullname = validate(type("string"), properties.fullname);
    this.role = validate(type("string"), properties.role);
    this.destino = validate(type("string"), properties.destino);
    this.tutorUsername = validate(type("string"), properties.tutorUsername);
    this.objetivo = validate(type("string"), properties.objetivo);
    this.urlImage = validate(type("string"), properties.urlImage);
    this.origem = validate(type("string"), properties.origem);
    this.idnumber = validate(type("string"), properties.idnumber);
  }
}
export default EnrolledDiscipline;
