import * as singleSpa from "single-spa";
import { reducers } from "../redux";

const register = () => {
  const showWhenPrefix = routes => location => routes.some(route => location.pathname.startsWith(route));
  const showExceptWhenPrefix = routes => location => routes.some(route => !location.pathname.startsWith(route));

  singleSpa.registerApplication("header",
    () => import("../apps/Header"),
    () => true);

  singleSpa.registerApplication("sidebar",
    () => import("../apps/Sidebar"),
    showExceptWhenPrefix(["/mail"]));

  singleSpa.registerApplication("teacher-material",
    () => import("portal-do-docente_material-do-professor"),
    showWhenPrefix(["/materials"]),
    { Reducer: reducers, env: process.env, baseUrl: "/materials" });

  // singleSpa.registerApplication("continuous-evaluation",
  //   () => import("frontend-lancamento-pontos"),
  //   showWhenPrefix(["/points"]),
  //   { Reducer: reducers, env: process.env, baseUrl: "/points" });

  // singleSpa.registerApplication("messaging",
  //   () => import("portal-do-docente-microfront-messaging"),
  //   showWhenPrefix(["/mail"]),
  //   { Reducer: reducers, env: process.env, baseUrl: "/mail" });
  //
  // singleSpa.registerApplication("calendar",
  //   () => import("portal-do-docente-datas-atividades"),
  //   showWhenPrefix(["/calendar"]),
  //   { Reducer: reducers, env: process.env, baseUrl: "/calendar" });

  singleSpa.start();
};

export default register;
