import * as Tour from "./TourAction";

const initialState = {
  openModal: true,
  automaticTour: false,
  type: undefined,
  status: "FETCHING",
  forceShow: false,
  show: false,
  step: undefined,
  name: undefined,
  isPage: true,
  concludedStatus: []
};
const tourReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGOUT":
      return action.status === "DONE"
        ? {
          ...initialState,
          type: action.type,
          error: action.error,
          status: action.status
        }
        : {
          ...state,
          type: action.type,
          error: action.error,
          status: action.status
        };

    case "persist/REHYDRATE":
      if (action.key === "global" && action.payload && action.payload.tour) {
        return {
          ...state,
          ...{
            concludedStatus: []
          }
        };
      }

      return state;
    case Tour._TYPES._forceShowPageGuidedTour:
      return {
        ...state,
        forceShow: action.forceShow,
        openModal: action.openModal,
        status: action.status,
        type: action.type
      };
    case Tour._TYPES._setGuidedTourStatus:
      return {
        ...state,
        concludedStatus: [],
        status: action.status,
        type: action.type
      };
    case Tour._TYPES._showPageGuidedTour:
      return {
        ...state,
        show: action.show,
        isPage: action.isPage,
        name: action.name,
        status: action.status,
        type: action.type
      };
    case Tour._TYPES._changeActiveGuidedTourStep:
      return {
        ...state,
        name: action.name,
        step: action.step,
        status: action.status,
        type: action.type
      };
    case Tour._TYPES._setAutomaticTour:
      return {
        ...state,
        automaticTour: true,
        status: action.status,
        type: action.type
      };
    case Tour._TYPES._setNotShowGuidedTourModal:
      return {
        ...state,
        type: action.type,
        forceShow: false,
        openModal: false,
        status: action.status,
        error: action.error
      };
    default:
      return state;
  }
};
export default tourReducer;
