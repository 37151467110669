export const _TYPES = {
  _setDebugMode: "SETTINGS::DEBUG_MODE"
};
export const _STATUS = {
  _fetching: "FETCHING",
  _done: "DONE",
  _failed: "FAILED"
};
const settings = {};
settings.setDebugMode = (active = false) => dispatch => {
  dispatch({ type: _TYPES._setDebugMode, active });
};
export default settings;
